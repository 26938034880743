import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('../views/index.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../views/contact.vue')
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('../views/list.vue')
	}, {
		path: '/detail',
		name: 'detail',
		component: () => import('../views/detail.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/about.vue')
	}
]
const router = new VueRouter({
	mode: "hash",
	routes
})

export default router
